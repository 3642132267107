import React from "react";
import { Box, Typography, Button } from "@material-ui/core";

const NotFoundPage = () => {
  return <Box>
    <Typography color={'primary'} variant="h1" component="h2" gutterBottom>404</Typography>
    <Typography variant="subtitle1">Please contact application Admin for assistance.</Typography>
    <br/>
    <Button variant="contained" color="primary" href="/">Go to Homepage</Button>
  </Box>
}

export default NotFoundPage;
